import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import scenario from "../../scenario.json";
import {applicationStates, LANGUAGES} from "../../constants";
import {addDoc, collection, doc, getDoc, getFirestore} from "firebase/firestore";
import {db} from "../../firebaseConfig";
import VideoPlayer from "../../components/VIdeoPlayer";
import Menu from "../../components/Menu";
import Episodes from "../../components/Episodes";
import RotateDevice from "../../components/RotateDevice";
import Poster from "../../components/Poster";
import ContactForm from "../../components/ContactForm";
import Intro from "../../components/Intro";
import AddCharacter from "../../components/AddCharacter";
import MakeChoice from "../../components/MakeChoice";
import ChoiceResult from "../../components/ChoiceResult";
import {useNavigate, useParams} from "react-router-dom";
import Localization from "../../components/Localization";

const Film = () => {
    const navigate = useNavigate();

    const validateToken = async (token) => {
        const tokenDocRef = doc(db, 'tokens', token);

        try {
            // Check if the document exists in Firestore
            const tokenSnapshot = await getDoc(tokenDocRef);

            if (tokenSnapshot.exists()) {
                // Document exists, now check expiry dates
                const { startDate, endDate, lng } = tokenSnapshot.data();

                const currentDate = new Date();

                // Convert startDate and endDate from Firestore Timestamp to Date
                const startDateDate = startDate.toDate();
                const endDateDate = endDate.toDate();

                // Check if the current date is within the valid date range
                if (currentDate >= startDateDate && currentDate <= endDateDate) {
                    console.log('Token is valid');
                    await i18n.changeLanguage(lng);
                    localStorage.setItem('language', lng)
                    // Token is valid, perform actions accordingly
                } else {
                    console.error('Token has expired');
                    navigate('/denied')
                    // Token has expired, handle accordingly
                }
            } else {
                console.error('Token not found');
                navigate('/denied')
                // Token not found in Firestore, handle accordingly
            }
        } catch (error) {
            console.error('Error validating token:', error.message);
            navigate('/denied')
            // Handle error while validating token
        }
    };


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('t');

        // Use the token as needed
        if (token) {
            validateToken(token)
            // Perform actions with the token (e.g., send it to the server)
        } else {
            navigate('/denied')
            console.error('Token is missing');
        }
    }, []);


    const playerRef = React.useRef(null);
    const { i18n } = useTranslation();
    const [currentStep, setCurrentStep] = useState(scenario.physical_bullying_intro);
    const [selectedChoices, setSelectedChoices] = useState([]);
    const [hideComponent, setHideComponent] = useState(false);
    const [applicationState, setApplicationState] = useState(applicationStates.init);
    const [showChoiceResult, setShowChoiceResult] = useState(false);
    const [showChoiceResultButton, setShowChoiceResultButton] = useState(false);
    const [rotateDevice, setRotateDevice] = useState(false);
    const [remainsFiveSeconds, setRemainsFiveSeconds] = useState(false);
    const [viewedEpisodes, setViewedEpisodes] = useState(
        JSON.parse(localStorage.getItem('episodes')) || []
    );
    const [isRotated, setIsRotated] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [session, setSession] = useState({
        age: null,
        gender: null,
        physical_bullying: [],
        psychological_bullying: [],
        cyber_bullying: [],
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
    });

    const videoJsOptions = {
        autoplay: currentStep.autoplay,
        playsinline: true,
        controls: false,
        userActions: {
            doubleClick: false
        },
        clickToPause: true,
        clickToPlay: true,
        fill: true,
        loop: currentStep.repeat,
        sources: [
            {
                src: i18n.language === LANGUAGES.hy ? currentStep.video_hy : currentStep.video_en
            }
        ]
        // muted: true
    };

    const handleVideoEnded = () => {
        setHideComponent((prevState) => false);
        setRemainsFiveSeconds((prevState) => false);

        setCurrentStep((prevState) => {
            if (prevState.repeat === true) {
                return prevState;
            }

            if (prevState.component === 'ChoiceResult') {
                setShowChoiceResultButton((prevState) => true);

                return prevState;
            }
            if (!prevState.next) {
                handleChangeApplicationState(applicationStates.end);
                return prevState;
            }
            return scenario[prevState.next];
        });
    };

    const handleSetNextStep = (step = null) => {
        if (step) {
            setCurrentStep(scenario[step]);
        } else {
            setCurrentStep(scenario[currentStep.next]);
        }
    };

    const handleMakeChoice = (choice) => {
        setRemainsFiveSeconds((prevState) => false);
        if (selectedChoices.length === 3) {
            setSelectedChoices([]);
        } else {
            setSelectedChoices([...selectedChoices, choice.id]);
        }
        handleSetNextStep(choice.next);
    };

    const handleChoiceResult = () => {
        // TODO refactor
        if (selectedChoices.length === 3 && currentStep.component === 'ChoiceResult') {
            setSession((prevState) => ({
                ...prevState,
                [currentStep.episode]: selectedChoices
            }));
            setSelectedChoices([]);
            setShowChoiceResult((prevState) => false);
            setShowChoiceResultButton((prevState) => false);
            setRemainsFiveSeconds((prevState) => false);
            handleSetNextStep(currentStep.next_episode);
            return;
        }

        setShowChoiceResult((prevState) => false);
        setShowChoiceResultButton((prevState) => false);
        setRemainsFiveSeconds((prevState) => false);
        handleSetNextStep();
    };

    const getChoiceResultButtonText = () => {
        if (selectedChoices.length === 1 && currentStep.is_correct) {
            return currentStep.correct_choice_text;
        }
        if (selectedChoices.length === 3) {
            return currentStep.last_button_text;
        }
        return currentStep.button_text;
    };

    const handleHideComponent = () => {
        setHideComponent((prevState) => true);
    };

    const handleStartFilm = () => {
        handleChangeApplicationState(applicationStates.started);
    };

    const handleShowChoiceResult = () => {
        setRemainsFiveSeconds((prevState) => true);
    };

    const handleChangeContactInfo = (e) => {
        const currentSession = { ...session };

        currentSession[e.target.name] = e.target.value;
        setSession(currentSession);
    };

    const handleSaveSession = async () => {
        try {
            const docRef = await addDoc(collection(db, 'sessions'), session);
            console.log('Session written with ID: ', docRef.id);
        } catch (e) {
            console.error('Error saving session: ', e);
        }
    };

    const handlePlayerReady = (player) => {
        if (!playerRef.current) {
            playerRef.current = player;
        }
    };

    const videoPlayer = useMemo(() => {
        return (
            <VideoPlayer
                options={videoJsOptions}
                onReady={handlePlayerReady}
                onEnd={handleVideoEnded}
                hideComponent={handleHideComponent}
                rotateDevice={setRotateDevice}
                setShowChoiceResult={handleShowChoiceResult}
            />
        );
    }, [currentStep.next]);

    const updateViewedEpisodes = () => {
        setViewedEpisodes((prevState) => {
            if (prevState.includes(currentStep.name)) {
                return prevState;
            }

            localStorage.setItem('episodes', JSON.stringify([...prevState, currentStep.name]));
            return [...prevState, currentStep.name];
        });
    };

    const handleChangeApplicationState = (state) => {
        setApplicationState(state);
    };

    const handleChooseEpisode = (episode) => {
        if (scenario[episode].component === 'ChoiceResult') {
            setSelectedChoices([scenario[episode].id]);
        }

        if (scenario[episode].component === 'MakeChoice') {
            setSelectedChoices([]);
        }

        setShowChoiceResult(false);
        setShowChoiceResultButton(false);
        setRemainsFiveSeconds(false);

        handleSetNextStep(episode);
    };

    useEffect(() => {
        if (remainsFiveSeconds && currentStep.component === 'ChoiceResult') {
            setShowChoiceResult(true);
        }
    }, [remainsFiveSeconds]);

    useEffect(() => {
        updateViewedEpisodes(currentStep);
    }, [currentStep]);

    useEffect(() => {
        if (applicationState === applicationStates.end) {
            handleSaveSession();
        }
    }, [applicationState]);

    useEffect(() => {
        if (selectedChoices.length === 3 && !currentStep.next_episode) {
            setShowChoiceResultButton((prevState) => false);
        }
    }, [showChoiceResultButton]);

    useEffect(() => {
        const handleRotationChange = () => {
            setIsRotated(window.orientation !== 0);
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleRotationChange();
        handleResize();

        window.addEventListener('orientationchange', handleRotationChange);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('orientationchange', handleRotationChange);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile && !isRotated) {
            playerRef.current && playerRef.current.pause();
            setRotateDevice(true);
        } else {
            setRotateDevice(false);
        }
    }, [isMobile, isRotated]);

    return (
        <div className="film">
            <a
                className="watermark"
                href="https://global-to-local.se/"
                target="_blank"
                rel="noreferrer"
            ></a>
            <Menu
                playerRef={playerRef}
                playOnHide={currentStep.autoplay}
                changeApplicationState={handleChangeApplicationState}
            />
            {applicationState === applicationStates.init && <Localization />}
            <Episodes
                viewedEpisodes={viewedEpisodes}
                playerRef={playerRef}
                playOnHide={currentStep.autoplay}
                chooseEpisode={handleChooseEpisode}
                changeApplicationState={handleChangeApplicationState}
            />
            {/*<HLSVideoPlayer*/}
            {/*    video={currentStep.video}*/}
            {/*    autoPlay={currentStep.autoplay}*/}
            {/*    loop={currentStep.repeat}*/}
            {/*    pause={showInfo}*/}
            {/*    onReady={handlePlayerReady}*/}
            {/*    onEnd={handleVideoEnded}*/}
            {/*    hideComponent={handleHideComponent}*/}
            {/*    rotateDevice={setRotateDevice}*/}
            {/*    setShowChoiceResult={handleShowChoiceResult}*/}
            {/*/>*/}
            {rotateDevice && <RotateDevice />}
            {applicationState !== applicationStates.init && videoPlayer}
            {applicationState === applicationStates.hide_components && <></>}
            {applicationState === applicationStates.init && (
                <Poster start={handleChangeApplicationState} />
            )}
            {applicationState === applicationStates.get_analytics && (
                <ContactForm start={handleStartFilm} handleChangeContactInfo={handleChangeContactInfo} />
            )}
            {applicationState === applicationStates.started && (
                <>
                    {currentStep.component === 'Intro' && (
                        <Intro
                            title={currentStep.title}
                            title2={currentStep.title2}
                            subtitle={currentStep.subtitle}
                            episode_name={currentStep.episode_name}
                            hideComponent={hideComponent}
                            background={currentStep.background}
                        />
                    )}
                    {currentStep.component === 'AddCharacter' && (
                        <AddCharacter text={currentStep.text} changeStep={handleSetNextStep} />
                    )}
                    {currentStep.component === 'MakeChoice' && (
                        <MakeChoice
                            choices={currentStep.choices}
                            selectedChoices={selectedChoices}
                            makeChoice={handleMakeChoice}
                        />
                    )}
                    {currentStep.component === 'ChoiceResult' && showChoiceResult && (
                        <ChoiceResult
                            choice={currentStep}
                            showChoiceResultButton={showChoiceResultButton}
                            selectedChoices={selectedChoices}
                            buttonText={getChoiceResultButtonText()}
                            changeStep={handleChoiceResult}
                        />
                    )}
                </>
            )}
            {/*{applicationState === applicationStates.end && <End/>}*/}
        </div>
    );
};

export default Film;